import isArray from '../utilities/is_array';
import isObject from '../utilities/is_object';
import { setDataAttributes } from './utils';

export const hasStimulusController = (element, controller) => element.dataset.controller?.includes?.(controller);

function generateControllerName(controller, { output = '' } = {}) {
  return output === '' ? controller : `${output} ${controller}`;
}

function generateControllersNames(controllers, { output = '' } = {}) {
  if (!controllers) return output;

  if (!isArray(controllers) || (controllers.length === 2 && isObject(controllers[controllers.length - 1]))) {
    return generateControllerName(controllers, { output });
  }

  for (const controller of controllers) {
    output = generateControllersNames(controller, { output });
  }

  return output;
}

function name(controllers) {
  if (!controllers) {
    console.warn('[STIMULUS]: invalid stimulus controller data.');
    return '';
  }

  if (isArray(controllers) && controllers.length === 1) {
    controllers = controllers[0];
  }

  if (!isArray(controllers) || (controllers.length === 2 && isObject(controllers[controllers.length - 1]))) {
    return generateControllerName(controllers);
  }

  return generateControllersNames(controllers);
}

function setStimulusController(element, controllers) {
  const names = name(controllers);

  setDataAttributes(element, { controller: names });
}

export default setStimulusController;
