import setStimulusAction from './action';
import setStimulusClasses from './classes';
import setStimulusController, { hasStimulusController } from './controller';
import setStimulusControllerDataValue from './controller_data_value';
import setStimulusOutlet from './outlet';
import setStimulusParam from './param';
import setStimulusTarget, { closestStimulusTarget, hasStimulusTarget } from './target';
import setStimulusValue from './value';

export const FUSE_TOOLTIP_CONTROLLER = 'fuse--tooltip';
export const FUSE_MODAL_TRIGGER_CONTROLLER = 'fuse--modal-trigger';
export const FUSE_SCROLL_REVEAL_CONTROLLER = 'fuse--scroll-reveal';

export const setStimulus = (
  element,
  { controller, controllerDataValue, value, classes, outlet, param, target, action } = {},
) => {
  if (value) {
    setStimulusValue(element, value);
  }

  if (controllerDataValue) {
    setStimulusControllerDataValue(element, controllerDataValue);
  }

  if (classes) {
    setStimulusClasses(element, classes);
  }

  if (outlet) {
    setStimulusOutlet(element, outlet);
  }

  if (param) {
    setStimulusParam(element, param);
  }

  if (action) {
    setStimulusAction(element, action);
  }

  if (target) {
    setStimulusTarget(element, target);
  }

  if (controller) {
    setStimulusController(element, controller);
  }
};

const stimulus = {
  FUSE_TOOLTIP_CONTROLLER,
  FUSE_SCROLL_REVEAL_CONTROLLER,
  FUSE_MODAL_TRIGGER_CONTROLLER,
  set: setStimulus,
  setController: setStimulusController,
  setControllerDataValue: setStimulusControllerDataValue,
  setValue: setStimulusValue,
  setClasses: setStimulusClasses,
  setOutlet: setStimulusOutlet,
  setParam: setStimulusParam,
  setTarget: setStimulusTarget,
  setAction: setStimulusAction,
  hasController: hasStimulusController,
  hasTarget: hasStimulusTarget,
  closestTarget: closestStimulusTarget,
};

export default stimulus;
