import toCamelCase from '../string/to_camel_case';
import isArray from '../utilities/is_array';
import isObject from '../utilities/is_object';
import { appendValue, setDataAttributes } from './utils';

function generateTargetsString(controller, target, { output }) {
  const key = `${controller}-target`;

  if (isArray(target)) {
    for (const t of target) {
      appendValue(output, key, toCamelCase(t));
    }

    return;
  }

  appendValue(output, key, toCamelCase(target));
}

function generateTargetsStrings(targets, { output = {}, controller = null } = {}) {
  if (!targets) return output;

  if (controller) {
    generateTargetsString(controller, targets, { output });

    return output;
  }

  if (isArray(targets)) {
    for (const target of targets) {
      generateTargetsStrings(target, { output, controller });
    }

    return output;
  }

  if (!isObject(targets)) {
    console.warn('[STIMULUS]: invalid stimulus target data.');
    return output;
  }

  for (const [c, target] of Object.entries(targets)) {
    generateTargetsStrings(target, { output, controller: c });
  }

  return output;
}

function setStimulusTarget(element, targets) {
  const data = generateTargetsStrings(targets);

  setDataAttributes(element, data);
}

export const hasStimulusTarget = (element, target) => {
  const data = generateTargetsStrings(target);

  return Object.entries(data).every(([key, value]) => element.matches(`[data-${key}~="${value}"]`));
};

export const closestStimulusTarget = (element, target) => {
  const data = generateTargetsStrings(target);
  const [key, value] = Object.entries(data)[0];

  return element.closest(`[data-${key}~="${value}"]`);
}

export default setStimulusTarget;
