import toDash from '../string/to_dash';
import isArray from '../utilities/is_array';
import isObject from '../utilities/is_object';
import { appendValue, setDataAttributes } from './utils';

function generateValuesString(controller, valueName, value, { output }) {
  const controllerWithoutNamespace = controller.split('--').slice(1).join('--');
  const key =
    valueName === '' || !valueName ? controllerWithoutNamespace : `${controllerWithoutNamespace}-${toDash(valueName)}`;

  appendValue(output, key, value);
}

function generateValuesStrings(values, { output = {}, controller = null } = {}) {
  if (!values) return output;

  if (isArray(values)) {
    for (const value of values) {
      generateValuesStrings(value, { output, controller });
    }

    return output;
  }

  if (!isObject(values)) {
    console.warn('[STIMULUS]: invalid stimulus controller data value data.');
    return output;
  }

  if (controller) {
    for (const [valueName, value] of Object.entries(values)) {
      generateValuesString(controller, valueName, value, { output });
    }

    return output;
  }

  for (const [c, value] of Object.entries(values)) {
    generateValuesStrings(value, { output, controller: c });
  }

  return output;
}

function setStimulusControllerDataValue(element, values) {
  const data = generateValuesStrings(values);

  setDataAttributes(element, data);
}

export default setStimulusControllerDataValue;
