import isArray from '../utilities/is_array';
import isObject from '../utilities/is_object';
import { setDataAttributes } from './utils';

const AVAILABLE_ACTIONS_MODIFIERS = ['capture', 'once', 'passive', '!passive', 'stop', 'prevent', 'self'];
const AVAILABLE_AT_MODIFIERS = ['window', 'document'];

const KEYBOARD_EVENTS = ['keypress', 'keydown', 'keyup'];
const AVAILABLE_KEY_FILTERS_REGEX = /^(enter|tab|esc|space|up|down|left|right|home|end|page_up|page_down|[a-z]|[0-9])$/;
const AVAILABLE_KEY_FILTER_MODIFIERS = ['alt', 'ctrl', 'meta', 'shift'];

function generateEventString(event) {
  let parts = event.split('@');
  const at = parts[1];
  parts = parts[0].split('.');
  const key = parts[1];
  event = parts[0];

  if (key) {
    let keyModifier = key.split('+');
    let keyFilter = key[1] || key[0];
    keyModifier = keyModifier.length > 1 ? keyModifier[0] : null;

    if (!event.includes(KEYBOARD_EVENTS)) {
      console.warn(`[STIMULUS]: invalid event key filter, only available for KeyboardEvent: ${event}`);
      keyFilter = null;
      keyModifier = null;
    } else if (!keyFilter.match(AVAILABLE_KEY_FILTERS_REGEX)) {
      console.warn(`[STIMULUS]: invalid event key filter: ${keyFilter}`);
      keyFilter = null;
      keyModifier = null;
    } else if (!AVAILABLE_KEY_FILTER_MODIFIERS.includes(keyModifier)) {
      console.warn(`[STIMULUS]: invalid event key modifier: ${keyModifier}`);
      keyModifier = null;
    }

    if (keyFilter) {
      if (keyModifier) {
        event += `.${keyModifier}+${keyFilter}`;
      } else {
        event += `.${keyFilter}`;
      }
    }
  }

  if (at) {
    if (!AVAILABLE_AT_MODIFIERS.includes(at)) {
      console.warn(`[STIMULUS]: invalid event at modifier: ${at}`);
    } else {
      event += `@${at}`;
    }
  }

  return event;
}

function actionNameWithOptions(action) {
  const parts = action.split(':').filter((modifier, index) => {
    if (index === 0) return true;

    if (AVAILABLE_ACTIONS_MODIFIERS.includes(modifier)) {
      return true;
    }

    console.warn(`[STIMULUS]: invalid action modifier: ${modifier}`);

    return false;
  });

  return parts.join(':');
}

function generateActionString(controller, action, { event, output }) {
  const actionName = actionNameWithOptions(action);
  const actionString = !event
    ? `${controller}#${action}`
    : `${generateEventString(event)}->${controller}#${actionName}`;

  return output === '' ? actionString : `${output} ${actionString}`;
}

function generateActionsString(actions, { output = '', event = null, controller = null } = {}) {
  if (!actions) return output;

  if (controller) {
    return generateActionString(controller, actions, { event, output });
  }

  if (!event && isArray(actions)) {
    for (const action of actions) {
      output = generateActionsString(action, { output });
    }

    return output;
  }

  if (!isObject(actions)) {
    console.warn('[STIMULUS]: invalid stimulus action data.');
    return output;
  }

  for (const [controllerOrEvent, action] of Object.entries(actions)) {
    if (isArray(action)) {
      // the first item is an object - an array of actions with controllers [{ 'fuse--first-component': 'run' }]
      if (isObject(action[0])) {
        if (event) {
          console.warn('[STIMULUS]: invalid stimulus action data.');
          return output;
        }

        for (const a of action) {
          output = generateActionsString(a, { output, event: controllerOrEvent });
        }

        return output;
      }

      // the last item is NOT a hash - an array of actions without controllers ['run_a', 'run_b']
      if (!isObject(action[action.length - 1])) {
        for (const a of action) {
          output = generateActionsString(a, { output, event, controller: controllerOrEvent });
        }

        return output;
      }
    }

    if (isObject(action)) {
      if (event) {
        console.warn('[STIMULUS]: invalid stimulus action data.');
      }

      output = generateActionsString(action, { output, event: controllerOrEvent });
    } else {
      output = generateActionsString(action, { output, event, controller: controllerOrEvent });
    }
  }

  return output;
}

function name(actions) {
  return generateActionsString(actions);
}

function setStimulusAction(element, actions) {
  const data = name(actions);

  setDataAttributes(element, { action: data });
}

export default setStimulusAction;
